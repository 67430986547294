/* eslint-disable */
import theme from 'styled-theming'

const themeName = 'colorTheme'

/* eslint-disable max-lines */
export const colorTheme = {
	main: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
	},
	section: {
		background: theme(themeName, { LIGHT: '#17192d', DARK: '#1D1F31' }),
	},
	basic: {
		textSpan: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
		textP: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		textP_red: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FF3E3E' }),
		styledA: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		heading: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
	},
	navBar: {
		background: theme(themeName, { LIGHT: '#17192d', DARK: '#1D1F31' }),
		borderTop: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
		title: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
		item: {
			counter: {
				background: theme(themeName, { LIGHT: '#383a50', DARK: '#383a50' }),
				backgroundActive: theme(themeName, { LIGHT: '#17192d', DARK: '#1D1F31' }),
				title: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
			},
			active: {
				background: theme(themeName, { LIGHT: '#383a50', DARK: '#383A4F' }),
				title: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			},
			icon: theme(themeName, { LIGHT: '#ADADB4', DARK: '#ADADB4' }),
			iconActive: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
		profile: {
			background: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.2)', DARK: 'rgba(0, 0, 0, 0.2)' }),
		},
		settingsItem: {
			background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
			title: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			green: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
			},
		},
		switch: {
			title: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
	},
	settingsBlocks: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#585865' }),
		border: theme(themeName, { LIGHT: '#2981EE', DARK: '#585865' }),
		borderHover: theme(themeName, { LIGHT: '#2981EE', DARK: '#A4ACB8' }),
		borderActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#2B2B2B' }),
		title: theme(themeName, { LIGHT: '#60616e', DARK: '#A8A8B7' }),
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
	},
	settingsTitle: {
		title: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
	},
	input: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
		text: theme(themeName, { LIGHT: '#777f8a', DARK: '#777F8A' }),
		field: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		fieldDisabled: theme(themeName, { LIGHT: '#000', DARK: '#777F8A' }),
		small: {
			border: theme(themeName, { LIGHT: '#adadb4', DARK: '#60616E' }),
		},
		purple: {
			border: theme(themeName, { LIGHT: '#9aaee1', DARK: '#60616E' }),
		},
		gray: {
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
		},
		blue: {
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#60616E' }),
		},
		focus: {
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			text: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
		},
		error: {
			background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			title: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#DE7B00' }),
			text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
		},
		checkBox: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			gray_background: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			icon: theme(themeName, { LIGHT: '#8F9BAB', DARK: '#2B2B2B' }),
			active: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#239768' }),
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			},
			disabled: {
				border: theme(themeName, { LIGHT: '#60616e', DARK: '#777F8A' }),
			},
		},
		checkBoxGray: {
			background: theme(themeName, { LIGHT: '#ECECEC', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#ECECEC', DARK: '#60616E' }),
			backgroundContainer: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			borderContainer: theme(themeName, { LIGHT: '#60616E', DARK: '#A4ACB8' }),
			text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
			icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			active: {
				background: theme(themeName, { LIGHT: '#ECECEC', DARK: '#2E5344' }),
				border: theme(themeName, { LIGHT: '#ECECEC', DARK: '#239768' }),
				backgroundContainer: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
				icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
			disabled: {
				background: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#383940' }),
				border: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#777F8A' }),
				text: theme(themeName, { LIGHT: '#777E89', DARK: '#777F8A' }),
			},
		},
		textarea: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			text: theme(themeName, { LIGHT: '#777f8a', DARK: '#777F8A' }),
			field: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			gray: {
				border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
			},
			focus: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			},
			error: {
				background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
				title: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#DE7B00' }),
				text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			},
		},
		select: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			backgroundOption: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			defaultOption: theme(themeName, { LIGHT: '#777f8a', DARK: '#777F8A' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
			icon: theme(themeName, { LIGHT: '#3689EF', DARK: '#64A9FF' }),
			purple: {
				border: theme(themeName, { LIGHT: '#9aaee1', DARK: '#60616E' }),
			},
			focus: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
				defaultOption: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#BACCE5' }),
			},
			error: {
				background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
				text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			},
			disabled: {
				background: theme(themeName, { LIGHT: '#ECECEC', DARK: '#60616E' }),
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				text: theme(themeName, { LIGHT: '#777F8A', DARK: '#BACCE5' }),
			},
		},
		radio: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#383940' }),
			text: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
			shadow: theme(themeName, { LIGHT: 'rgb(164, 172, 184)', DARK: 'rgba(119, 127, 138, 1)' }),
			shadowOutside: theme(themeName, { LIGHT: 'rgb(250, 250, 250)', DARK: 'rgba(56, 57, 64, 1)' }),
			active: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
				shadow: theme(themeName, { LIGHT: 'rgb(56, 205, 144)', DARK: 'rgba(56, 205, 144, 1)' }),
				shadowOutside: theme(themeName, { LIGHT: 'rgb(250, 250, 250)', DARK: 'rgba(56, 57, 64, 1)' }),
			},
			disabled: {
				background: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
			},
		},
		radioImage: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#383940' }),
			border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			shadow: theme(themeName, { LIGHT: 'rgb(164, 172, 184)', DARK: 'rgba(119, 127, 138, 1)' }),
			shadowOutside: theme(themeName, { LIGHT: 'rgb(250, 250, 250)', DARK: 'rgba(56, 57, 64, 1)' }),
			active: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
				shadow: theme(themeName, { LIGHT: 'rgb(56, 205, 144)', DARK: 'rgba(56, 205, 144, 1)' }),
				shadowOutside: theme(themeName, { LIGHT: 'rgb(250, 250, 250)', DARK: 'rgba(56, 57, 64, 1)' }),
			},
		},
		switch: {
			background: theme(themeName, { LIGHT: '#adadb4', DARK: '#777F8A' }),
			border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
			color: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			active: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
				border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
			},
		},
		search: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			text: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			placeholder: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
		},
		searchModal: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#64A9FF' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
			placeholder: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
		},
		time: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
			text: theme(themeName, { LIGHT: '#777f8a', DARK: '#777F8A' }),
			success: {
				border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
			},
			disabled: {
				border: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#e1e1e1' }),
				text: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#e1e1e1' }),
			},
			focus: {
				border: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
				text: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
			},
			error: {
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
				text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			},
		},
		pinCode: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#777f8a', DARK: '#60616E' }),
			text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
			focus: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			},
			error: {
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			},
		},
		image: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#777F8A' }),
			label: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			image: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			focus: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
			error: {
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FF3E3E' }),
			},
			picker: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
				title: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
				border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#A4ACB8' }),
			},
			preview: {
				border: theme(themeName, { LIGHT: '#ADADB4', DARK: '#A4ACB8' }),
			},
			item: {
				border: theme(themeName, { LIGHT: '#ADADB4', DARK: '#A4ACB8' }),
			},
		},
		color: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#777F8A' }),
			label: theme(themeName, { LIGHT: '#000000', DARK: '#64A9FF' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			image: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			focus: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
			error: {
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FF3E3E' }),
			},
			gray: {
				label: theme(themeName, { LIGHT: '#777f8a', DARK: '#64A9FF' }),
				text: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFFFFF' }),
			},
			circle: {
				border: theme(themeName, { LIGHT: '#adadb4', DARK: '#777F8A' }),
			},
			picker: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
				title: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				input: {
					background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
					text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
					label: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				},
			},
			item: {
				border: theme(themeName, { LIGHT: '#ADADB4', DARK: '#A4ACB8' }),
			},
		},
	},
	message: {
		success: {
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			text: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
		},
		error: {
			icon: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		},
		info: {
			text: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
		},
	},
	modal: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
		backgroundLightGray: theme(themeName, { LIGHT: '#EEE', DARK: '#EEE' }),
		border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#A4ACB8' }),
		header: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			shadow: theme(themeName, { LIGHT: '#00000026', DARK: '#00000026' }),
			border: theme(themeName, { LIGHT: '#777f8a', DARK: '#777F8A' }),
			defaultText: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			blueText: theme(themeName, { LIGHT: '#2981EE', DARK: '#BACCE5' }),
			redText: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
		},
		footer: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			line: theme(themeName, { LIGHT: '#989da5', DARK: '#FFFFFF' }),
		},
		closeKey: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#CDCDCE' }),
			text: theme(themeName, { LIGHT: '#989da5', DARK: '#CDCDCE' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#DE7B00' }),
		},
		action: {
			text: theme(themeName, { LIGHT: '#2981EE', DARK: '#BACCE5' }),
		},
		agreement: {
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
		},
		buttons: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#383a50', DARK: '#60616E' }),
			borderHover: theme(themeName, { LIGHT: '#38cd90', DARK: '#777F8A' }),
			icon: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFFFFF' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#A4ACB8' }),
			active: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
				border: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				disabled: {
					background: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#3F3F46' }),
					border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
					text: theme(themeName, { LIGHT: '#E1E1E1', DARK: '#777F8A' }),
					icon: theme(themeName, { LIGHT: '#E1E1E1', DARK: '#777F8A' }),
				},
			},
			disabled: {
				background: theme(themeName, { LIGHT: '#F0F0F0', DARK: '#3F3F46' }),
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
				text: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
				icon: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
			},
		},
		position: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#A4ACB8' }),
			title: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
			text: theme(themeName, { LIGHT: '#777E89', DARK: '#777F8A' }),
			icon: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
			close: theme(themeName, { LIGHT: '#2981EE', DARK: '#DE7B00' }),
			button: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#A4ACB8' }),
				icon: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
				text: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
			},
			error: {
				border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			},
		},
		info: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			text: theme(themeName, { LIGHT: '#383A50', DARK: '#CDE3FF' }),
			warning: {
				border: theme(themeName, { LIGHT: '#DE7B00', DARK: '#9B5600' }),
				icon: theme(themeName, { LIGHT: '#DE7B00', DARK: '#9B5600' }),
				header: {
					background: theme(themeName, { LIGHT: '#DE7B00', DARK: '#9B5600' }),
				},
			},
			info: {
				border: theme(themeName, { LIGHT: '#777F8A', DARK: '#585865' }),
				icon: theme(themeName, { LIGHT: '#777F8A', DARK: '#585865' }),
				header: {
					background: theme(themeName, { LIGHT: '#777F8A', DARK: '#585865' }),
				},
			},
			error: {
				border: theme(themeName, { LIGHT: '#B40000', DARK: '#8F2F36' }),
				icon: theme(themeName, { LIGHT: '#B40000', DARK: '#8F2F36' }),
				header: {
					background: theme(themeName, { LIGHT: '#B40000', DARK: '#8F2F36' }),
				},
			},
			header: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
				defaultText: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
			closeKey: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
				border: theme(themeName, { LIGHT: '#CDCDCE', DARK: '#CDCDCE' }),
				text: theme(themeName, { LIGHT: '#CDCDCE', DARK: '#CDCDCE' }),
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#DE7B00' }),
			},
		},
	},
	tabs: {
		border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
		tab: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
			text: theme(themeName, { LIGHT: '#2981EE', DARK: '#A4ACB8' }),
			active: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				error: {
					background: theme(themeName, { LIGHT: '#B40000', DARK: '#6C1818' }),
					border: theme(themeName, { LIGHT: '#B40000', DARK: '#6C1818' }),
					text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				},
			},
			error: {
				text: theme(themeName, { LIGHT: '#B40000', DARK: '#FF3E3E' }),
			},
			counter: {
				background: theme(themeName, { LIGHT: '#DE7B00', DARK: '#2981EE' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				error: {
					background: theme(themeName, { LIGHT: '#B40000', DARK: '#FF3E3E' }),
				},
				active: {
					background: theme(themeName, { LIGHT: '#DE7B00', DARK: '#FFF' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#000' }),
					error: {
						background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
						text: theme(themeName, { LIGHT: '#000', DARK: '#060606' }),
					},
				},
			},
		},
	},
	loaders: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
		hourglass: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#585865' }),
			border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#585865' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			dark: {
				border: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#585865' }),
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
		},
		circle: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			blue: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			},
		},
	},
	tooltip: {
		background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
		lightBackground: theme(themeName, { LIGHT: '#64A9FF', DARK: '#2263B2' }),
		border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
		text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		activeText: theme(themeName, { LIGHT: '#FBFF36', DARK: '#FBFF36' }),
		outlineBorder: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
	},
	recaptcha: {
		text: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
	},
	logo: {
		text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
	},
	keyboard: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
		close: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#DE7B00' }),
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
		},
		key: {
			background: theme(themeName, { LIGHT: '#383a50', DARK: '#60616E' }),
			text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			border: theme(themeName, { LIGHT: 'transparent', DARK: '#A4ACB8' }),
			hover: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			},
			clear: {
				background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
				icon: theme(themeName, { LIGHT: '#FEFEFE', DARK: '#FEFEFE' }),
			},
			enter: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
				icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
		},
	},
	deviceSwitch: {
		background: theme(themeName, { LIGHT: 'transparent', DARK: '#FFFFFF' }),
		border: theme(themeName, { LIGHT: '#B8B8B8', DARK: '#2B2B2B' }),
		text: theme(themeName, { LIGHT: '#7F8692', DARK: '#7F8692' }),
		active: {
			background: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
			text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
	},
	accordion: {
		background: theme(themeName, { LIGHT: 'transparent', DARK: '#383940' }),
		border: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
		text: theme(themeName, { LIGHT: '#60616e', DARK: '#FFFFFF' }),
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		arrow_icon: theme(themeName, { LIGHT: '#60616E', DARK: '#FFFFFF' }),
		gray: {
			background: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#60616E' }),
		},
	},
	badges: {
		background: theme(themeName, { LIGHT: '#ACACB3', DARK: '#60616E' }),
		text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
	},
	price: {
		background: theme(themeName, { LIGHT: '#777f8a', DARK: '#60616E' }),
		border: theme(themeName, { LIGHT: '#777f8a', DARK: '#60616E' }),
		text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		grayDark: {
			background: theme(themeName, { LIGHT: '#60616e', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#3F3F46' }),
		},
		blue: {
			background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
		},
		active: {
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#000000' }),
		},
	},
	toggleButton: {
		border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
		background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
		filledBackground: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
		whiteColor: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
		grayColor: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
		blueColor: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		purpleColor: theme(themeName, { LIGHT: '#383A50', DARK: '#FFFFFF' }),
		disabled: theme(themeName, { LIGHT: '#989da5', DARK: '#60616E' }),
	},
	checkboxButton: {
		border: theme(themeName, { LIGHT: '#A9B0BA', DARK: '#60616E' }),
		background: theme(themeName, { LIGHT: '#EFEFEF', DARK: '#2B2B2B' }),
		text: theme(themeName, { LIGHT: '#A9B0BA', DARK: '#777F8A' }),
		shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25)' }),
		active: {
			background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
		checkbox: {
			border: theme(themeName, { LIGHT: '#A9B0BA', DARK: '#A4ACB8' }),
			background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
			text: theme(themeName, { LIGHT: '#A9B0BA', DARK: '#777F8A' }),
			icon: theme(themeName, { LIGHT: '#A9B0BA', DARK: '#777F8A' }),
			active: {
				background: theme(themeName, { LIGHT: '#383A50', DARK: '#1D1D1D' }),
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
		},
	},
	uploadImage: {
		border: theme(themeName, { LIGHT: '#2981EE', DARK: '#777F8A' }),
		background: theme(themeName, { LIGHT: '#fff', DARK: '#383940' }),
		text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFFFFF' }),
		title: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
		default: {
			border: theme(themeName, { LIGHT: '#60616E', DARK: '#777F8A' }),
			background: theme(themeName, { LIGHT: '#F7F7F7', DARK: '#383940' }),
			icon: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
			text: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
		},
		success: {
			border: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
		},
		error: {
			border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FF3E3E' }),
			background: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FF3E3E' }),
			text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FBFF36' }),
			loader: theme(themeName, { LIGHT: '#2981EE', DARK: '#FF3E3E' }),
		},
		moderation: {
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			loader: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		},
		conditions: {
			text: theme(themeName, { LIGHT: '#000000', DARK: '#fff' }),
		},
	},
	moderationStatus: {
		noImage: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#A4ACB8' }),
		pending: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		approved: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
		rejected: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FF3E3E' }),
	},
	checkmark: {
		check: {
			circle: theme(themeName, { LIGHT: '#DCDCDC', DARK: '#38CD90' }),
			icon: theme(themeName, { LIGHT: '#38CD90', DARK: '#FFFFFF' }),
		},
		cross: {
			circle: theme(themeName, { LIGHT: '#DCDCDC', DARK: '#A4ACB8' }),
			icon: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#FFFFFF' }),
		},
	},
	moveButton: {
		background: theme(themeName, { LIGHT: '#adadb4', DARK: '#ADADB4' }),
		active: {
			background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
		},
		disabled: {
			background: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#60616E' }),
		},
		activeDisabled: {
			background: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#60616E' }),
		},
	},
	fileUploadButton: {
		background: theme(themeName, { LIGHT: '#adadb4', DARK: '#60616E' }),
		border: theme(themeName, { LIGHT: '#adadb4', DARK: '#60616E' }),
		text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		green: {
			background: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
			border: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
		},
	},
	dropDownSelect: {
		background: theme(themeName, { LIGHT: '#17192d', DARK: '#1D1F31' }),
		border: theme(themeName, { LIGHT: '#adadb4', DARK: '#ADADB4' }),
		item: {
			text: theme(themeName, { LIGHT: '#adadb4', DARK: '#ADADB4' }),
			hover: {
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			},
			selected: {
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
		},
	},
	dropDownButton: {
		background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#777F8A' }),
		border: theme(themeName, { LIGHT: '#383a50', DARK: '#777F8A' }),
		text: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
		icon: theme(themeName, { LIGHT: '#383A50', DARK: '#FFFFFF' }),
		active: {
			background: theme(themeName, { LIGHT: '#383a50', DARK: '#60616E' }),
			border: theme(themeName, { LIGHT: '#383a50', DARK: '#60616E' }),
			icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
	},
	keyIcon: {
		border: theme(themeName, { LIGHT: 'rgba(162, 162, 162, 0.7)', DARK: '#38CD90' }),
		text: theme(themeName, { LIGHT: 'rgba(185, 185, 185, 0.7)', DARK: '#38CD90' }),
	},
	button: {
		type: {
			success: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
			successPress: theme(themeName, { LIGHT: '#239768', DARK: '#000' }),
			primary: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			primaryPress: theme(themeName, { LIGHT: '#2263B2', DARK: '#000' }),
			danger: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
			dangerPress: theme(themeName, { LIGHT: '#8F2F36', DARK: '#000' }),
			warning: theme(themeName, { LIGHT: '#DE7B00', DARK: '#8F2F36' }),
			warningPress: theme(themeName, { LIGHT: '#4A070C', DARK: '#000' }),
			secondary: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
			secondaryPress: theme(themeName, { LIGHT: '#2B2B2B', DARK: '#000' }),
			light: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			secondary_light: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#777F8A' }),
			secondary_gray_light: theme(themeName, { LIGHT: '#777F8A', DARK: '#60616E' }),
			disabled: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#585865' }),
			outline: theme(themeName, { LIGHT: 'transparent', DARK: '#2B2B2B' }),
			borderHover: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),

		},
		color: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		buttonKey: {
			color: theme(themeName, { LIGHT: '#FFF', DARK: '#ffffffb3' }),
			border: theme(themeName, { LIGHT: '#FFF', DARK: '#ffffffb3' }),
			colorGray: theme(themeName, { LIGHT: '#60616e', DARK: '#ffffffb3' }),
			borderGray: theme(themeName, { LIGHT: '#60616e', DARK: '#ffffffb3' }),
		},
		count: {
			background: theme(themeName, { LIGHT: '#adadb4', DARK: '#ffffffb3' }),
			color: theme(themeName, { LIGHT: '#17192d', DARK: '#17192d' }),
			backgroundOutline: theme(themeName, { LIGHT: '#EFEFF3', DARK: '#EFEFF3' }),
		},

	},
	form: {
		formInfoText: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
		dividingLine: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
		errorMessage: {
			border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FBFF36' }),
			text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FBFF36' }),
		},
	},
	table: {
		tableTh: {
			background: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
			color: theme(themeName, { LIGHT: '#989da5', DARK: '#FFF' }),
			sortBy: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			icon: theme(themeName, { LIGHT: '#777F8A', DARK: '#FFF' }),
			tooltip: {
				color: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				active: theme(themeName, { LIGHT: '#FBFF36', DARK: '#FBFF36' }),
			},
		},
		tableTr: {
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			borderBottom: theme(themeName, { LIGHT: '#989da5', DARK: '#A4ACB8' }),
			boldBorder: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
			mobileBackground: theme(themeName, { LIGHT: '#989da5', DARK: '#A4ACB8' }),
			mobileBorder: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
			mobileTitle: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
			dangerText: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FF3E3E' }),
			textLight: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
			driverTime: theme(themeName, { LIGHT: '#060606', DARK: '#BACCE5' }),
			colorType: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
			backgroundType: theme(themeName, { LIGHT: '#EAECEF', DARK: '#667387' }),
			icon: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			tableLabel: {
				background: theme(themeName, { LIGHT: '#ACACB3', DARK: '#60616E' }),
				color: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
				backgroundLightGray: theme(themeName, { LIGHT: '#adadb4', DARK: '#60616E' }),
				backgroundDark: theme(themeName, { LIGHT: '#ACACB3', DARK: '#2B2B2B' }),
				backgroundGreen: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
				backgroundRed: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
			},
			disabled: {
				text: theme(themeName, { LIGHT: '#adadb4', DARK: '#777F8A' }),
			},
			disabledNewTable: {
				text: theme(themeName, { LIGHT: '#B7B7B7', DARK: '#777F8A' }),
			},
			lastGroupBorder: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
			partnerDriverTracker: {
				background: theme(themeName, { LIGHT: '#D4E1FB', DARK: '#D4E1FB' }),
				color: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				icon: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
			},
			active: {
				backgroundTr: theme(themeName, { LIGHT: '#fff', DARK: '#353642' }),
				background: theme(themeName, { LIGHT: '#fff', DARK: 'transparent' }),
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				backgroundOrderType: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
		},
		emptyTableTr: {
			color: theme(themeName, { LIGHT: '#777E89', DARK: '#777F8A' }),
			icon: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#A4ACB8' }),
		},
		tableTd: {
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#A4ACB8' }),
			background: theme(themeName, { LIGHT: '#fff', DARK: 'transparent' }),
			color: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			categoryBorder: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
			icon: theme(themeName, { LIGHT: '#777f8a', DARK: '#64A9FF' }),
			disabled: {
				background: theme(themeName, { LIGHT: '#E5E5E5', DARK: '#60616E' }),
				icon: theme(themeName, { LIGHT: '#999999', DARK: '#777F8A' }),
			},
		},
		infoShield: {
			background: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#64A9FF' }),
			textItem: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
		},
		distanceBlock: {
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			background: theme(themeName, { LIGHT: '#777f8a', DARK: '#2B2B2B' }),
		},
		preOrderType: {
			background: theme(themeName, { LIGHT: '#777F8A', DARK: '#2B2B2B' }),
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#A4ACB8' }),
		},
		newBlock: {
			background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			color: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
	},
	cart: {
		textCartTableHead: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFF' }),
		shadowCartTotal: theme(themeName, { LIGHT: '#000000', DARK: '#383A50' }),
		cartProduct: {
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#60616E' }),
			strikethrough: theme(themeName, { LIGHT: '#8F2F36', DARK: '#FBFF36' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#BACCE5' }),
			productQuantity: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
			textSecondary: theme(themeName, { LIGHT: '#826244', DARK: '#A9722E' }),
			active: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#353642' }),
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),
			},
			price: {
				discountedPrice: theme(themeName, { LIGHT: '#777f8a', DARK: '#64A9FF' }),
				afterDiscount: theme(themeName, { LIGHT: '#239768', DARK: '#38CD90' }),
			},
			topping: {
				borderTop: theme(themeName, { LIGHT: '#989da5', DARK: '#60616E' }),
				borderTouchPad: theme(themeName, { LIGHT: '#2981EE', DARK: '#2B2B2B' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#64A9FF' }),
				name: theme(themeName, { LIGHT: '#060606', DARK: '#BACCE5' }),
				removeButton: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
				removeButtonTouchPad: theme(themeName, { LIGHT: '#2981EE', DARK: '#60616E' }),
				icon: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				minus: {
					border: theme(themeName, { LIGHT: '#e67d0c', DARK: '#2B2B2B' }),
					text: theme(themeName, { LIGHT: '#000', DARK: '#DE7B00' }),
					background: theme(themeName, { LIGHT: '#FEF3E4', DARK: '#2B2B2B' }),
					removeButton: theme(themeName, { LIGHT: '#e67d0c', DARK: '#60616E' }),
				},
				touchPad: {
					name: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
					icon: theme(themeName, { LIGHT: '#000', DARK: '#64A9FF' }),
				},
				price: {
					background: theme(themeName, { LIGHT: '#777f8a', DARK: '#3F3F46' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#BACCE5' }),
				},
			},
			plusMinusButtons: {
				plus: {
					icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					green: {
						border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
						plusBackground: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
					},
					gray: {
						icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
						border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
						background: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
					},
					transparent: {
						icon: theme(themeName, { LIGHT: '#adadb4', DARK: '#FFF' }),
						border: theme(themeName, { LIGHT: '#adadb4', DARK: '#383940' }),
						background: theme(themeName, { LIGHT: '#FFF', DARK: '#383940' }),
					},
				},
				minus: {
					icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					background: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
					border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
					outline: {
						icon: theme(themeName, { LIGHT: '#60616e', DARK: '#FFF' }),
						border: theme(themeName, { LIGHT: '#60616e', DARK: '#383940' }),
						background: theme(themeName, { LIGHT: '#FFF', DARK: '#383940' }),
					},
					gray: {
						icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
						border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
						background: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
					},
					transparent: {
						icon: theme(themeName, { LIGHT: '#adadb4', DARK: '#FFF' }),
						border: theme(themeName, { LIGHT: '#adadb4', DARK: '#383940' }),
						background: theme(themeName, { LIGHT: '#FFF', DARK: '#383940' }),
					},
				},
			},
		},
		discountInput: {
			input: {
				text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			},
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			borderActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			currency: {
				text: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
			},
		},
		extraDiscountInput: {
			input: {
				text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			},
			select: {
				text: theme(themeName, { LIGHT: '#777F8A', DARK: '#BACCE5' }),
			},
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			borderActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			currency: {
				text: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
			},
		},
		productSearch: {
			background: theme(themeName, { LIGHT: 'transparent', DARK: '#60616E' }),
			inputSearch: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#95C0F6' }),
				placeholder: theme(themeName, { LIGHT: '#95C0F6', DARK: '#95C0F6' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			},
			productList: {
				backgroundWrapper: theme(themeName, { LIGHT: 'transparent', DARK: '#60616E' }),
				backgroundProduct: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				backgroundProductTouchPad: theme(themeName, { LIGHT: '#FFF', DARK: '#60616E' }),
				activeBackgroundProduct: theme(themeName, { LIGHT: '#9aaee1', DARK: '#64A9FF' }),
				shadowProduct: theme(themeName, { LIGHT: 'rgba(10, 28, 63, 0.34)', DARK: 'rgba(0, 0, 0, 0.55)' }),
				backgroundTopping: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#383A50' }),
				borderTopping: theme(themeName, { LIGHT: '#adadb4', DARK: '#A4ACB8' }),
				text: theme(themeName, { LIGHT: '#000000', DARK: '#BACCE5' }),
				textActive: theme(themeName, { LIGHT: '#000000', DARK: '#FFF' }),
				productNr: {
					border: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
					activeBorder: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),
					text: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
					activeText: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),

				},
				productPrice: {
					border: theme(themeName, { LIGHT: '#777f8a', DARK: '#60616E' }),
					background: theme(themeName, { LIGHT: '#777f8a', DARK: '#60616E' }),
					activeBackground: theme(themeName, { LIGHT: 'transparent', DARK: '#2981EE' }),
					activeBorder: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					activeText: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),
				},
			},
			menuSelection: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#383A50' }),
				title: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
				titleIcon: theme(themeName, { LIGHT: '#2881EE', DARK: '#FFF' }),
				borderList: theme(themeName, { LIGHT: '#777F8A', DARK: '#A4ACB8' }),
				product: {
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
					border: theme(themeName, { LIGHT: '#969BA2', DARK: '#777F8A' }),
					text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
					textActive: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					borderActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					backgroundActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					icon: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#BACCE6' }),
					iconActive: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				},
				menuItem: {
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
					backgroundContentFocused: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
					backgroundFocused: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
					borderFocused: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
					textFocused: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					itemName: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
					icon: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
					iconClose: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#60616E' }),
					toppingBorder: theme(themeName, { LIGHT: '#60616E', DARK: '#585865' }),
					toppingText: theme(themeName, { LIGHT: '#60616E', DARK: '#8BBEFE' }),
					backgroundText: theme(themeName, { LIGHT: '#FFF', DARK: '#585865' }),
					iconCheck: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#64A9FF' }),
				},
			},
		},
		cartTotal: {
			shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.15)', DARK: 'rgba(0, 0, 0, 0.15)' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#383A50' }),
			border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
			text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			bold: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
			totalBorder: theme(themeName, { LIGHT: '#383A50', DARK: '#A4ACB8' }),
		},
		mobileCartButton: {
			background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
			backgroundHover: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			textHover: theme(themeName, { LIGHT: '#000', DARK: '#000s' }),
			btnHover: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
			icon: theme(themeName, { LIGHT: '#2881EE', DARK: '#2881EE' }),
			iconHover: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
		customerName: {
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
		customerAddress: {
			text: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
		},
		cartPayment: {
			title: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			total: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		},
		dishCourse: {
			selectButton: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				textDark: theme(themeName, { LIGHT: '#30356C', DARK: '#30356C' }),
				icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				iconDark: theme(themeName, { LIGHT: '#30356C', DARK: '#30356C' }),
				toggle: theme(themeName, { LIGHT: '#2263B2', DARK: '#2263B2' }),//TODO: darkTheme
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),//TODO: darkTheme
				options: {
					border: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),//TODO: darkTheme
					borderBottom: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),//TODO: darkTheme
					text: theme(themeName, { LIGHT: '#000', DARK: '#000' }),//TODO: darkTheme
					backgroundDefault: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#E0E0E0' }),//TODO: darkTheme
				},
			},
			modal: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
				borderActive: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
				defaultBackground: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#E0E0E0' }),
			},
		},
	},
	cartCategory: {
		color: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
		shadow: theme(themeName, { LIGHT: '#00000033', DARK: '#00000033' }),
		giftVoucher: {
			background: theme(themeName, { LIGHT: '#2981EE', DARK: '#585865' }),
		},
		categoryButton: {
			color: theme(themeName, { LIGHT: '#383a50', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#383a50', DARK: '#2B2B2B' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#60616E' }),
			active: {
				color: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: '#383a50', DARK: '#60616E' }),
				background: theme(themeName, { LIGHT: '#383a50', DARK: '#2B2B2B' }),
			},
		},
		favCategoryButton: {
			color: theme(themeName, { LIGHT: '#000000', DARK: '#FFF' }),
			icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			background: {
				button_8n1: theme(themeName, { LIGHT: '#f3e0f0', DARK: '#2B2B2B' }),
				button_8n2: theme(themeName, { LIGHT: '#d0cdf0', DARK: '#2B2B2B' }),
				button_8n3: theme(themeName, { LIGHT: '#cbede9', DARK: '#2B2B2B' }),
				button_8n4: theme(themeName, { LIGHT: '#96dea8', DARK: '#2B2B2B' }),
				button_8n5: theme(themeName, { LIGHT: '#e6bda9', DARK: '#2B2B2B' }),
				button_8n6: theme(themeName, { LIGHT: '#8aa5ce', DARK: '#2B2B2B' }),
				button_8n7: theme(themeName, { LIGHT: '#ffb7b7', DARK: '#2B2B2B' }),
				button_8n8: theme(themeName, { LIGHT: '#f1d3a3', DARK: '#2B2B2B' }),
			},
			active: {
				color: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			},
			focus: {
				color: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.40)', DARK: 'rgba(0, 0, 0, 0.40)' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			},
			back: {
				color: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				background: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
				border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
				focus: {
					color: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.40)', DARK: 'rgba(0, 0, 0, 0.40)' }),
					border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
				},
			},
			touchPad: {
				color: theme(themeName, { LIGHT: '#000000', DARK: '#FFF' }),
			},
		},
	},
	cashDesk: {
		background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
	},
	cartModal: {
		modalPayment: {
			input: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
				border: theme(themeName, { LIGHT: '#383a50', DARK: '#64A9FF' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				focus: {
					text: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
					border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				},
				disabled: {
					background: theme(themeName, { LIGHT: '#F0F0F0', DARK: '#3F3F46' }),
					border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
					text: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
				},
			},
			paymentMethod: {
				terminal: {
					text: theme(themeName, { LIGHT: '#383A50', DARK: '#64A9FF' }),
					icon: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#64A9FF' }),
				},
			},
		},
		modalSize: {
			ingredients: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			sizeButton: {
				border: theme(themeName, { LIGHT: '#adadb4', DARK: '#777F8A' }),
				hoverBorder: theme(themeName, { LIGHT: '#2981EE', DARK: '#CDE3FF' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#777F8A' }),
				active: {
					border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
				},
			},
			productQuantity: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#989da5', DARK: '#2B2B2B' }),
				plus: {
					background: theme(themeName, { LIGHT: '#ECF4FF', DARK: '#2B2B2B' }),
					border: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				},
				minus: {
					background: theme(themeName, { LIGHT: '#FEF3E4', DARK: '#2B2B2B' }),
					border: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
				},
				focus: {
					border: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
					border720: theme(themeName, { LIGHT: '#989da5', DARK: '#989da5' }),
				},
				title: theme(themeName, { LIGHT: '#000', DARK: '#BACCE5' }),
				plusTitle: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				minusTitle: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
				input: {
					border: theme(themeName, { LIGHT: '#adadb4', DARK: '#474856' }),
					text: theme(themeName, { LIGHT: '#adadb4', DARK: '#A4ACB8' }),
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
					active: {
						border: theme(themeName, { LIGHT: '#9aaee1', DARK: '#A4ACB8' }),
						text: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
						background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					},
				},
			},
			remarkInput: {
				border: theme(themeName, { LIGHT: '#60616e', DARK: '#777F8A' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				placeholder: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
				icon: theme(themeName, { LIGHT: '#979BA4', DARK: '#BACCE5' }),
				focus: {
					border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
				},
				keyboard: {
					border: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#777F8A' }),
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				},
			},
		},
		changeCalculator: {
			input: {
				getMoney: {
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
					text: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),
					border: theme(themeName, { LIGHT: '#60616e', DARK: '#64A9FF' }),
					shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.41)', DARK: 'rgba(0, 0, 0, 0.41)' }),
					labelText: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				},
				change: {
					background: theme(themeName, { LIGHT: '#EEEEEE', DARK: '#585865' }),
					lightText: theme(themeName, { LIGHT: '#383a50', DARK: '#A4ACB8' }),
					text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
					border: theme(themeName, { LIGHT: '#60616e', DARK: '#FFF' }),
				},
			},
			sum: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#585865' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				hoverBorder: theme(themeName, { LIGHT: '#9aaee1', DARK: '#9aaee1' }),
			},
			numbers: {
				background: theme(themeName, { LIGHT: '#383a50', DARK: '#60616e' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: 'transparent', DARK: '#A4ACB8' }),
				hoverBackground: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				clear: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
				enter: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
			},
		},
		terminalProcess: {
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			text: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFF' }),
			error: theme(themeName, { LIGHT: '#B40000', DARK: '#DE7B00' }),
		},
		invoiceModals: {
			invoiceConfigs: {
				subtitle: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				description: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
			},
			customerData: {
				subtitle: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				description: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
				autocompleteItem: {
					text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
					icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
					active: {
						text: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
						icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
					},
				},
			},
		},
		qrCodePayment: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#8BBEFE', DARK: '#8BBEFE' }),
			text: theme(themeName, { LIGHT: '#777F8A', DARK: '#FFF' }),
			error: {
				icon: theme(themeName, { LIGHT: '#B40000', DARK: '#DE7B00' }),
				border: theme(themeName, { LIGHT: '#FF3E3E', DARK: '#FF3E3E' }),
				text: theme(themeName, { LIGHT: '#B40000', DARK: '#DE7B00' }),
			},
			success: {
				icon: theme(themeName, { LIGHT: '#38CD90', DARK: '#239768' }),
				border: theme(themeName, { LIGHT: '#90F0C9', DARK: '#90F0C9' }),
			},
		},
	},
	woltDeliverySettings: {
		info: {
			border: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
			icon: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
			text: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
		},
		error: {
			border: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			icon: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			text: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
		},
	},
	kitchenMonitor: {
		cartProduct: {
			borderTop: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
			borderBottom: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
			quantity: {
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
			},
			total: {
				background: theme(themeName, { LIGHT: '#777f8a', DARK: '#777f8a' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			},
			topping: {
				borderTop: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2B2B2B' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#64A9FF' }),
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
			remark: {
				border: theme(themeName, { LIGHT: '#777f8a', DARK: '#777f8a' }),
			},
			minus: {
				background: theme(themeName, { LIGHT: '#60616e', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#e67d0c', DARK: '#2B2B2B' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#DE7B00' }),
				icon: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
			},
			canceled: {
				text: theme(themeName, { LIGHT: '#B40000', DARK: '#FF3E3E' }),
			},
		},
		cartMenu: {
			border: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
		},
		cartDishCourse: {
			text: theme(themeName, { LIGHT: '#060606', DARK: '#060606' }),
			borderTop: theme(themeName, { LIGHT: '#989da5', DARK: '#777F8A' }),
		},
		orderCard: {
			background: theme(themeName, { LIGHT: '#fff', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#E5E5E5', DARK: '#777F8A' }),
			remark: theme(themeName, { LIGHT: '#777f8a', DARK: '#777f8a' }),
			shadow: theme(themeName, { LIGHT: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%)', DARK: 'linear-gradient(180deg, rgba(63, 63, 70, 0.00) 0%, #3F3F46 100%)' }),
		},
		orderTab: {
			background: theme(themeName, { LIGHT: '#fff', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#fff', DARK: '#777F8A' }),
			button: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
			item: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#3F3F46' }),
				restaurant: {
					background: theme(themeName, { LIGHT: '#fff', DARK: '#3F3F46' }),
					backgroundGray: theme(themeName, { LIGHT: '#60616e', DARK: '#3F3F46' }),
				},
			},
		},
		timeScale: {
			box: {
				blue: theme(themeName, { LIGHT: '#ECF4FF', DARK: '#2B2B2B' }),
				orange: theme(themeName, { LIGHT: '#FEF3E4', DARK: '#2B2B2B' }),
			},
			timeText: {
				blue: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				orange: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
				black: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			},
			progressBar: {
				background: theme(themeName, { LIGHT: '#adadb4', DARK: '#585865' }),
				backgroundBlue: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				backgroundOrange: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
			},
		},
		icon: {
			blue: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			orange: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
		},
		orderTypeItem: {
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			restaurant: {
				background: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
				backgroundGray: theme(themeName, { LIGHT: '#60616e', DARK: '#60616E' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#000000' }),
				textGray: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
			},
			waiter: {
				text: theme(themeName, { LIGHT: '#60616e', DARK: '#FFFFFF' }),
			},
		},
		orders: {
			background: theme(themeName, { LIGHT: '#17192d', DARK: '#17192d' }),
			textNoOrders: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
		},
		orderDetails: {
			total: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		},
	},
	allOrders: {
		select: {
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#60616E' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			optionsShadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25' }),
			optionBorder: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#60616E' }),
			optionBorderWrap: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#FFF' }),
			optionBackgroundHover: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			text: theme(themeName, { LIGHT: '#060606', DARK: '#A4ACB8' }),
			textActive: theme(themeName, { LIGHT: '#060606', DARK: '#FFF' }),
			textHover: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
	},
	clientMonitorCart: {
		logo: {
			background: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.80)', DARK: 'rgba(0, 0, 0, 0.80)' }),
			color: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
		cart: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			header: {
				color: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
				border: theme(themeName, { LIGHT: '#A5A9B0', DARK: '#A5A9B0' }),
			},
			product: {
				border: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
				title: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
				price: {
					initial: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
					total: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
					active: theme(themeName, { LIGHT: '#239768', DARK: '#239768' }),
					discounted: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
				},
				topping: {
					title: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
					price: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
				},
				coupon: theme(themeName, { LIGHT: '#239768', DARK: '#239768' }),
			},
			total: {
				border: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
				borderLight: theme(themeName, { LIGHT: '#A5A9B0', DARK: '#A5A9B0' }),
				title: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
				price: theme(themeName, { LIGHT: '#060606', DARK: '#060606' }),
			},
		},
		finalStep: {
			background: theme(themeName, { LIGHT: '#1D1D1D', DARK: '#1D1D1D' }),
			title: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
		qrPaymentStep: {
			background: theme(themeName, { LIGHT: '#1D1D1D', DARK: '#1D1D1D' }),
			content: {
				title: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				border: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			},
		},
	},
	datevSettings: {
		title: theme(themeName, { LIGHT: '#060606', DARK: '#060606' }),
		accountTitle: theme(themeName, { LIGHT: '#1C1C1C', DARK: '#1C1C1C' }),
	},
	incomeCallModal: {
		table: {
			icon: theme(themeName, { LIGHT: '#777F8A', DARK: '#64A9FF' }),
			text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			active: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			background: theme(themeName, { LIGHT: '#F4F4F4', DARK: '#383940' }),
			secondBackground: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
		},
		selectedCall: {
			icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			iconBackground: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			active: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25)' }),
		},
	},
	partnerPortal: {
		background: theme(themeName, { LIGHT: '#FFF', DARK: '#1D1D1D' }),
		table: {
			header: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				backgroundOdd: theme(themeName, { LIGHT: '#F1F7FF', DARK: '#1D1D1D' }),
			},
			title: theme(themeName, { LIGHT: '#60616E', DARK: '#FFFFFF' }),
			period: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
			total: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			statistics: theme(themeName, { LIGHT: '#060606', DARK: '#64A9FF' }),
			border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
			row: {
				border: theme(themeName, { LIGHT: '#A5A9B0', DARK: '#A4ACB8' }),
				background: theme(themeName, { LIGHT: '#F1F7FF', DARK: '#1D1D1D' }),
				tablet: {
					border: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
					header: theme(themeName, { LIGHT: '#FFF', DARK: '#1D1D1D' }),
				},
				total: {
					border: theme(themeName, { LIGHT: '#060606', DARK: '#FFF' }),
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
					backgroundEven: theme(themeName, { LIGHT: '#F1F7FF', DARK: '#1D1D1D' }),
					text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
					shadow: theme(themeName, { LIGHT: '0px -10px 40px 0px #FFF', DARK: '0px -10px 40px 0px #3F3F46' }),
				},
			},
			column: {
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
				title: theme(themeName, { LIGHT: '#777F8A', DARK: '#FFFFFF' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
			},
			companyIndex: {
				background: theme(themeName, { LIGHT: '#30356C', DARK: '#173A64' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFFFFF' }),
			},
			shopInfoOrdersCount: {
				title: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				text: theme(themeName, { LIGHT: '#474856', DARK: '#FFFFFF' }),
			},
			companyInfo: {
				text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
				textGray: theme(themeName, { LIGHT: '#777F8A', DARK: '#A4ACB8' }),
				link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
			noInfo: {
				text: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				icon: theme(themeName, { LIGHT: '#908F8F', DARK: '#A4ACB8' }),
				iconCircle: theme(themeName, { LIGHT: '#C5C5C5', DARK: '#8BBEFE' }),
			},
			activation: {
				backgroundOrange: theme(themeName, { LIGHT: '#DE7B00', DARK: '#9B5600' }),
				backgroundRed: theme(themeName, { LIGHT: '#B40000', DARK: '#8F2F36' }),
				backgroundGray: theme(themeName, { LIGHT: '#777F8A', DARK: '#585865' }),
				backgroundGrayLight: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#FFFFFF' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				textGray: theme(themeName, { LIGHT: '#FFF', DARK: '#CDCDCE' }),
			},
			prolongation: {
				warn: theme(themeName, { LIGHT: '#DE7B00', DARK: '#9B5600' }),
				error: theme(themeName, { LIGHT: '#B40000', DARK: '#8F2F36' }),
				info: theme(themeName, { LIGHT: '#60616E', DARK: '#585865' }),
				text: theme(themeName, { LIGHT: '#474856', DARK: '#FFFFFF' }),
				shadow: theme(themeName, { LIGHT: 'rgb(255, 255, 255)', DARK: 'rgb(255, 255, 255)' }),
				shadowOutside: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25)' }),
			},
			conditions: {
				text: theme(themeName, { LIGHT: '#60616E', DARK: '#A4ACB8' }),
			},
			servicesStatusInfo: {
				background: theme(themeName, { LIGHT: '#38CD90', DARK: '#239768' }),
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				border: theme(themeName, { LIGHT: '#239768', DARK: '#239768' }),
				disabled: {
					background: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#777F8A' }),
				},
				rejected: {
					background: theme(themeName, { LIGHT: '#B40000', DARK: '#B40000' }),
				},
				restricted: {
					background: theme(themeName, { LIGHT: '#FBECBC', DARK: '#FBECBC' }),
					text: theme(themeName, { LIGHT: '#8D3410', DARK: '#8D3410' }),
				},
			},
			deactivationStripeModal: {
				text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFFFFF' }),
			},
		},
		tooltip: {
			background: theme(themeName, { LIGHT: 'rgba(56, 58, 80, 0.95)', DARK: 'rgba(183, 0, 143, 1)' }),
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			icon: theme(themeName, { LIGHT: '#383A50', DARK: '#B7008F' }),
		},
		input: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			selectedText: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			placeholder: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			icon: theme(themeName, { LIGHT: '#ADADB4', DARK: '#DE7B00' }),
			active: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			},
			items: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#767676', DARK: '#FFF' }),
			},
			item: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#BACCE5' }),
				lightText: theme(themeName, { LIGHT: '#777F8A', DARK: '#A4ACB8' }),
				icon: theme(themeName, { LIGHT: '#060606', DARK: '#64A9FF' }),
				hover: {
					background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
					text: theme(themeName, { LIGHT: '#fff', DARK: '#FFFFFF' }),
					icon: theme(themeName, { LIGHT: '#fff', DARK: '#FFFFFF' }),
				},
			},
		},
		selectInput: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			border: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
			text: theme(themeName, { LIGHT: '#000000', DARK: '#FFF' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#3689EF' }),
			focus: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			},
			options: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#767676', DARK: '#FFF' }),
			},
			option: {
				border: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#777F8A' }),
				backgroundDealer: theme(themeName, { LIGHT: '#CDE3FF', DARK: '#173A64' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#BACCE5' }),
				textDealer: theme(themeName, { LIGHT: '#2263B2', DARK: '#64A9FF' }),
				hover: {
					background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFF' }),
				},
			},
		},
		skeleton: {
			background: theme(themeName, { LIGHT: 'linear-gradient(273deg, #E1E1E1 41.15%, #FFF 69.27%, #E1E1E1 100%)', DARK: 'linear-gradient(273deg, #656575 41.15%, #9F9FBC 69.27%, #656575 100%)' }),
		},
		dealerProvision: {
			title: theme(themeName, { LIGHT: '#2981EE', DARK: '#BACCE5' }),
			text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			tooltipText: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
		provisionPrepareReportModal: {
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#A4ACB8' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
		},
		provisionReportModal: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			borderGray: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#383a50' }),
			dropDownButton: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				borderTop: theme(themeName, { LIGHT: '#000000', DARK: '#000000' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#060606' }),
				active: {
					background: theme(themeName, { LIGHT: '#FEF3E4', DARK: '#FEF3E4' }),
				},
			},
			table: {
				title: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#060606' }),
				header: {
					background: theme(themeName, { LIGHT: '#ECF4FF', DARK: '#ECF4FF' }),
					border: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
					title: theme(themeName, { LIGHT: '#383a50', DARK: '#383a50' }),
				},
				footer: {
					background: theme(themeName, { LIGHT: '#FEF3E4', DARK: '#FEF3E4' }),
					border: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
					borderTop: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
					title: theme(themeName, { LIGHT: '#060606', DARK: '#060606' }),
				},
			},
		},
		periods: {
			background: theme(themeName, { LIGHT: '#F3F3F3', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#767676', DARK: '#FFF' }),
			inputs: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#1D1D1D' }),
			},
			input: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#60616E' }),
				text: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
			},
			calendar: {
				background: theme(themeName, { LIGHT: '#FEFFDB', DARK: '#173A64' }),
				selected: {
					background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
					text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				},
			},
		},
	},
	datepicker: {
		borderTop: theme(themeName, { LIGHT: '#989da5', DARK: '#60616E' }),
		text: theme(themeName, { LIGHT: '#777f8a', DARK: '#060606' }),
		arrow: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),
		icon: theme(themeName, { LIGHT: '#777f8a', DARK: '#64A9FF' }),
		day: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#1D1D1D' }),
			text: theme(themeName, { LIGHT: '#262626', DARK: '#A4ACB8' }),
			textGray: theme(themeName, { LIGHT: '#777f8a', DARK: '#60616E' }),
			selected: {
				background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			},
			disabled: {
				background: theme(themeName, { LIGHT: '#adadb4', DARK: '#33343b' }),
			},
		},
		popper: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#1D1D1D' }),
		},
		header: {
			text: theme(themeName, { LIGHT: '#000', DARK: '#BACCE5' }),
		},
		select: {
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
		},
	},
	deliveryConfig: {
		deliveryTabs: {
			info: {
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				text: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
			},
		},
		addPolygonButton: {
			icon: theme(themeName, { LIGHT: '#60616E', DARK: '#FFFFFF' }),
			text: theme(themeName, { LIGHT: '#60616E', DARK: '#FFFFFF' }),
		},
		polygon: {
			name: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
			disabled: {
				name: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				color: theme(themeName, { LIGHT: '#DBDBE8', DARK: '#DBDBE8' }),
			},
			form: {
				background: theme(themeName, { LIGHT: '#F9F9F9', DARK: '#3F3F46' }),
				border: theme(themeName, { LIGHT: '#A7A8B4', DARK: '#A4ACB8' }),
				switchColorText: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
				arrowIcon: theme(themeName, { LIGHT: '#777F8A', DARK: '#FFFFFF' }),
			},
			info: {
				text: theme(themeName, { LIGHT: '#60616E', DARK: '#FFFFFF' }),
				currency: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
				disabled: {
					text: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
					currency: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				},
			},
			zipSearch: {
				tag: {
					background: theme(themeName, { LIGHT: '#777F8A', DARK: '#60616E' }),
					icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
					modifiedBackground: theme(themeName, { LIGHT: '#8F2F36', DARK: '#6C1818' }),
					text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
					addZone: {
						background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
						border: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
						color: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
					},
				},
				autocomplete: {
					background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
					border: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
					shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25)' }),
					item: {
						background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
						activeBackground: theme(themeName, { LIGHT: '#CDE3FF', DARK: '#2263B2' }),
						checkbox: {
							border: theme(themeName, { LIGHT: '#60616E', DARK: '#A4ACB8' }),
							text: theme(themeName, { LIGHT: '#3F3F46', DARK: '#BACCE5' }),
							checked: {
								background: theme(themeName, { LIGHT: '#383A50', DARK: '#239768' }),
								border: theme(themeName, { LIGHT: '#383A50', DARK: '#239768' }),
								text: theme(themeName, { LIGHT: '#3F3F46', DARK: '#FFFFFF' }),
							},
						},
						empty: theme(themeName, { LIGHT: '#60616E', DARK: '#A4ACB8' }),
					},
				},
			},
		},
	},
	woltRestaurant: {
		woltHelpQrCode: {
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#A4ACB8' }),
			background: theme(themeName, { LIGHT: '#EEE', DARK: '#2B2B2B' }),
			text: theme(themeName, { LIGHT: '#060606', DARK: '#FFF' }),
			link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			qrCode: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: '#777F8A', DARK: 'transparent' }),
			},
		},
	},
	platformQrCodeDataModal: {
		border: theme(themeName, { LIGHT: '#777F8A', DARK: '#FFF' }),
		background: theme(themeName, { LIGHT: '#EEE', DARK: '#2B2B2B' }),
		text: theme(themeName, { LIGHT: '#383A50', DARK: '#FFF' }),
		qrCode: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#777F8A', DARK: 'transparent' }),
		},
		jsonBackground: theme(themeName, { LIGHT: '#FFF', DARK: '#000' }),
	},
	home: {
		title: theme(themeName, { LIGHT: '#000000', DARK: '#FFF' }),
		item: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#585865' }),
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#585865' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			text: theme(themeName, { LIGHT: '#60616e', DARK: '#A8A8B7' }),
			borderHover: theme(themeName, { LIGHT: '#2981EE', DARK: '#A4ACB8' }),
			borderActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#2B2B2B' }),
		},
	},
	profile: {
		avatar: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		name: theme(themeName, { LIGHT: '#FFF', DARK: '#E6E7E9' }),
		index: theme(themeName, { LIGHT: '#FFF', DARK: '#E6E7E9' }),
		background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
		border: theme(themeName, { LIGHT: '#FFF', DARK: '#A4ACB8' }),
		companyText: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		qrCode: {
			background: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
		icon: theme(themeName, { LIGHT: '#777F8A', DARK: '#BACCE5' }),
		dealerTitle: theme(themeName, { LIGHT: '#000', DARK: '#BACCE5' }),
	},
	restaurantSettings: {
		text: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
		table: {
			backgroundWhite: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
			background: theme(themeName, { LIGHT: '#7A7A7A', DARK: '#7A7A7A' }),
			title: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			backgroundChair: theme(themeName, { LIGHT: '#7A7A7A', DARK: '#7A7A7A' }),
			borderChair: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
		wall: {
			border: theme(themeName, { LIGHT: '#999', DARK: '#999' }),
		},
		grid: {
			label: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		},
	},
	restaurant: {
		background: theme(themeName, { LIGHT: 'rgba(255, 255, 255, 0.6)', DARK: 'rgba(255, 255, 255, 0.6)' }),
		border: theme(themeName, { LIGHT: '#CD3838', DARK: '#CD3838' }),
		icons: {
			tableOpen: {
				background: theme(themeName, { LIGHT: 'transparent', DARK: 'transparent' }),
				stroke: theme(themeName, { LIGHT: '#ADADB4', DARK: '#777F8A' }),
				green: {
					background: theme(themeName, { LIGHT: 'transparent', DARK: 'transparent' }),
					stroke: theme(themeName, { LIGHT: '#38C98E', DARK: '#38C98E' }),
				},
				white: {
					background: theme(themeName, { LIGHT: 'transparent', DARK: 'transparent' }),
					stroke: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				},
			},
			tableClosed: {
				background: theme(themeName, { LIGHT: '#D4D4D4', DARK: '#2B2B2B' }),
				stroke: theme(themeName, { LIGHT: '#1D1F31', DARK: '#BACCE6' }),
				white: {
					stroke: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				},
			},
		},
		tableMobile: {
			background: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
			busy: {
				background: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#383A50' }),
				number: theme(themeName, { LIGHT: '#1D1F31', DARK: '#FFFFFF' }),
			},
			selected: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
				icon: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
				number: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
				price: theme(themeName, { LIGHT: '#fff', DARK: '#2B2B2B' }),
			},
			number: theme(themeName, { LIGHT: '#60616e', DARK: '#FFFFFF' }),
			numberSwap: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
			price: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
		},
		table: {
			backgroundWhite: theme(themeName, { LIGHT: '#fff', DARK: '#fff' }),
			background: theme(themeName, { LIGHT: '#7A7A7A', DARK: '#7A7A7A' }),
			title: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			backgroundChair: theme(themeName, { LIGHT: '#7A7A7A', DARK: '#7A7A7A' }),
			borderChair: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			price: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			busy: {
				background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
			},
		},
		wall: {
			border: theme(themeName, { LIGHT: '#999', DARK: '#999' }),
		},
		area: {
			button: {
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				name: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				nameBlack: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				mobile: {
					background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
					icon: theme(themeName, { LIGHT: '#383A50', DARK: '#2981EE' }),
				},
				count: {
					background: theme(themeName, { LIGHT: '#EFEFF3', DARK: '#EFEFF3' }),
					text: theme(themeName, { LIGHT: '#17192d', DARK: '#17192d' }),
				},
				active: {
					border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
					name: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
					nameBlack: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
					mobile: {
						background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#2B2B2B' }),
					},
					count: {
						background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
					},
				},
			},
			waiterCall: {
				background: theme(themeName, { LIGHT: '#DE7B00', DARK: '#DE7B00' }),
			},
		},
		areas: {
			background: theme(themeName, { LIGHT: '#E5E5E5', DARK: '#585865' }),
			tables: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
		},
		swapModal: {
			border: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
		},
		splitModal: {
			split: {
				border: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
				name: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
				price: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				active: {
					background: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
					name: theme(themeName, { LIGHT: '#EFEFF3', DARK: '#777F8A' }),
					price: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#000000' }),
				},
			},
			table: {
				border: theme(themeName, { LIGHT: '#adadb4', DARK: '#777F8A' }),
				header: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
				borderProducts: theme(themeName, { LIGHT: '#989da5', DARK: '#60616E' }),
			},
		},
		splitBoardModal: {
			split: {
				border: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
				name: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
				price: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				icon: theme(themeName, { LIGHT: '#777F8A', DARK: '#BACCE5' }),
				active: {
					background: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
					name: theme(themeName, { LIGHT: '#EFEFF3', DARK: '#777F8A' }),
					price: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#000000' }),
					icon: theme(themeName, { LIGHT: '#EFEFF3', DARK: '#777F8A' }),
				},
			},
			table: {
				border: theme(themeName, { LIGHT: '#adadb4', DARK: '#777F8A' }),
				header: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
				borderProducts: theme(themeName, { LIGHT: '#989da5', DARK: '#60616E' }),
			},
		},
		dishCourse: {
			defaultBackground: theme(themeName, { LIGHT: '#E0E0E0', DARK: '#E0E0E0' }),
			text: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
		},
		waiterCall: {
			background: theme(themeName, { LIGHT: '#DE7B00', DARK: '#DE7B00' }),
			text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#DE7B00', DARK: '#DE7B00' }),
			areas: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				area: {
					text: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
				},
				waiter: {
					background: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				},
			},
		},
	},
	restaurantDiscount: {
		title: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
		message: {
			title: theme(themeName, { LIGHT: '#60616E', DARK: '#BACCE5' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		},
		select: {
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#60616E' }),
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
			optionsShadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25' }),
			optionBorder: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#60616E' }),
			optionBorderWrap: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#FFF' }),
			optionBackgroundHover: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
			textHover: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
		},
	},
	restaurantCartSettings: {
		presetDiscounts: {
			title: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
			border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#FFFFFF' }),
			borderDark: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
		},
	},
	restaurantCart: {
		dishCourses: {
			border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
			borderMobile: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
			title: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
			dishCourse: {
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				border: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
				name: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
				circleBorder: theme(themeName, { LIGHT: '#CDCDCE', DARK: '#CDCDCE' }),
			},
		},
	},
	tableCart: {
		table: {
			waiter: {
				text: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				background: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
				border: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
			},
			waiterCall: {
				background: theme(themeName, { LIGHT: '#DE7B00', DARK: '#DE7B00' }),
			},
		},
	},
	productTableCategory: {
		borderHover: theme(themeName, { LIGHT: '#38CD90', DARK: '#2263B2' }),
		borderActive: theme(themeName, { LIGHT: '#2981EE', DARK: '#000' }),
		borderTopActive: theme(themeName, { LIGHT: '#000', DARK: '#000' }),
	},
	paymentSettings: {
		border: theme(themeName, { LIGHT: '#989da5', DARK: '#A4ACB8' }),
		switch: {
			label: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
			icon: theme(themeName, { LIGHT: '#8F9BAB', DARK: '#777F8A' }),
			checked: {
				label: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			},
		},
		button: {
			background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
			border: theme(themeName, { LIGHT: '#adadb4', DARK: '#A4ACB8' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#8BBEFE' }),
			text: theme(themeName, { LIGHT: '#2981EE', DARK: '#8BBEFE' }),
			hover: {
				border: theme(themeName, { LIGHT: '#2981EE', DARK: '#8BBEFE' }),
				shadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.25)', DARK: 'rgba(0, 0, 0, 0.25)' }),
			},
		},
		online: {
			title: {
				icon: theme(themeName, { LIGHT: '#8F9BAB', DARK: '#777F8A' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
				active: {
					icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
					text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				},
			},
			item: {
				icon: theme(themeName, { LIGHT: '#777f8a', DARK: '#777F8A' }),
				text: theme(themeName, { LIGHT: '#777f8a', DARK: '#A4ACB8' }),
				active: {
					icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
					text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				},
			},
		},
		invoice: {
			text: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
		},
		activatePlatformModal: {
			icon: theme(themeName, { LIGHT: '#e67d0c', DARK: '#DE7B00' }),
			text: theme(themeName, { LIGHT: '#383a50', DARK: '#FFF' }),
			activeText: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#A4ACB8' }),
		},
		paypal: {
			deactivateModal: {
				text: theme(themeName, { LIGHT: '#383a50', DARK: '#FFF' }),
			},
		},
		terminal: {
			background: theme(themeName, { LIGHT: '#F5F5F5', DARK: '#383940' }),
			border: theme(themeName, { LIGHT: '#F5F5F5', DARK: '#60616E' }),
			title: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
			disabledTitle: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
			infoMessage: {
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
				errorText: theme(themeName, { LIGHT: '#B40000', DARK: '#DE7B00' }),
				info: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				success: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
				error: theme(themeName, { LIGHT: '#B40000', DARK: '#DE7B00' }),
			},
			timer: {
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#8BBEFE' }),
			},
			checkbox: {
				container: {
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
					border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#60616E' }),
				},
				border: theme(themeName, { LIGHT: '#60616E', DARK: '#A4ACB8' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#2B2B2B' }),
				label: theme(themeName, { LIGHT: '#777E89', DARK: '#FFFFFF' }),
				checked: {
					container: {
						background: theme(themeName, { LIGHT: '#FFF', DARK: '#2E5344' }),
						border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#239768' }),
					},
					background: theme(themeName, { LIGHT: '#38cd90', DARK: '#239768' }),
					label: theme(themeName, { LIGHT: '#000', DARK: '#FFFFFF' }),
					icon: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#FFFFFF' }),
				},
				disable: {
					container: {
						background: theme(themeName, { LIGHT: '#FFF', DARK: '#383940' }),
						border: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#777F8A' }),
					},
					background: theme(themeName, { LIGHT: '#FFF', DARK: '#383940' }),
					border: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#777F8A' }),
					label: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#777F8A' }),
					checked: {
						background: theme(themeName, { LIGHT: '#C4C4C4', DARK: '#60616E' }),
					},
				},
			},
			configurationTitle: {
				color: theme(themeName, { LIGHT: '#000', DARK: '#A4ACB8' }),
				active: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			},
			radio: {
				border: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#BACCE5' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#3F3F46' }),
				label: theme(themeName, { LIGHT: '#777F8A', DARK: '#BACCE5' }),
				innerBorder: theme(themeName, { LIGHT: 'rgb(250, 250, 250)', DARK: '#3F3F46' }),
				outBorder: theme(themeName, { LIGHT: 'rgb(164, 172, 184)', DARK: '#777F8A' }),
				active: {
					background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
					label: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
					innerBorder: theme(themeName, { LIGHT: 'rgb(250, 250, 250)', DARK: '#3F3F46' }),
					outBorder: theme(themeName, { LIGHT: 'rgb(56, 205, 144)', DARK: '#38CD90' }),
				}
			}
		},
	},
	invoices: {
		table: {
			orange: theme(themeName, { LIGHT: '#FF7A00', DARK: '#DE7B00' }),
			green: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
		},
		modal: {
			invoiceData: {
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				background: theme(themeName, { LIGHT: '#FFF', DARK: '#383A50' }),
				border: theme(themeName, { LIGHT: '#777F8A', DARK: '#60616E' }),
				backgroundHeader: theme(themeName, { LIGHT: '#F2F2F2', DARK: '#60616E' }),
				borderProduct: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#A4ACB8' }),
				label: {
					backgroundGreen: theme(themeName, { LIGHT: '#38CD90', DARK: '#38CD90' }),
					backgroundOrange: theme(themeName, { LIGHT: '#FF7A00', DARK: '#DE7B00' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
					icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				},
			},
			resendEmail: {
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			},
		},
	},
	orderAndPaySettings: {
		switchText: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		config: {
			title: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			border: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
			previewShadow: theme(themeName, { LIGHT: 'rgba(0, 0, 0, 0.15)', DARK: 'rgba(0, 0, 0, 0.15)' }),
		},
	},
	orders: {
		table: {
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			status: {
				color: {
					beingPrepared: theme(themeName, { LIGHT: '#008fd9', DARK: '#64A9FF' }),
					onTheWay: theme(themeName, { LIGHT: '#008fd9', DARK: '#64A9FF' }),
					completed: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
					canceled: theme(themeName, { LIGHT: '#eb0901', DARK: '#FF3E3E' }),
					notAccepted: theme(themeName, { LIGHT: '#ffa921', DARK: '#FEA821' }),
					notRegistered: theme(themeName, { LIGHT: '#B7008F', DARK: '#FD96FF' }),
					denied: theme(themeName, { LIGHT: '#eb0901', DARK: '#FF3E3E' }),
					ready: theme(themeName, { LIGHT: '#008fd9', DARK: '#64A9FF' }),
				},
			},
		},
	},
	permissions: {
		text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		mobileText: theme(themeName, { LIGHT: '#1C1C1C', DARK: '#FFF' }),
	},
	platformDriversModal: {
		border: theme(themeName, { LIGHT: '#989da5', DARK: '#989da5' }),
		info: theme(themeName, { LIGHT: '#8F2F36', DARK: '#8F2F36' }),
		text: {
			dark: theme(themeName, { LIGHT: '#000000', DARK: '#000000' }),
			purple: theme(themeName, { LIGHT: '#383A50', DARK: '#383A50' }),
		},
		icon: {
			gray: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			blue: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			darkBlue: theme(themeName, { LIGHT: '#2263B2', DARK: '#2263B2' }),
		},
		buttons: {
			background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			text: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#e1e1e1' }),
			icon: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#e1e1e1' }),
			active: {
				background: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
				text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				icon: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				shadow: theme(themeName, { LIGHT: 'rgba(56, 205, 144, 0.5)', DARK: 'rgba(56, 205, 144, 0.5)' }),
			},
			disabled: {
				background: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#B1B1B1' }),
				border: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#B1B1B1' }),
			},
		},
		preorderBorder: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
	},
	report: {
		monthlyReportModal: {
			border: theme(themeName, { LIGHT: '#777F8A', DARK: '#777F8A' }),
			text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			label: theme(themeName, { LIGHT: '#383A50', DARK: '#BACCE5' }),
			icon: theme(themeName, { LIGHT: '#777f8A', DARK: '#64A9FF' }),
		},
	},
	payPalWarningModal: {
		title: theme(themeName, { LIGHT: '#383A50', DARK: '#CDE3FF' }),
		text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		contacts: {
			title: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
		},
	},
	extraContactsModal: {
		title: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
		text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
	},
	deactivationModal: {
		title: theme(themeName, { LIGHT: '#383A50', DARK: '#CDE3FF' }),
		text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		contacts: {
			title: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
		},
	},
	tseInformationModal: {
		title: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		text: theme(themeName, { LIGHT: '#60616e', DARK: '#A8A8B7' }),
		icon: theme(themeName, { LIGHT: '#777f8a', DARK: '#BACCE5' }),
		empty: {
			title: theme(themeName, { LIGHT: '#777f8a', DARK: '#A4ACB8' }),
			icon: theme(themeName, { LIGHT: '#B1B1B1', DARK: '#A4ACB8' }),
		},
	},
	tseInvalidDataModal: {
		text: theme(themeName, { LIGHT: '#383a50', DARK: '#FFFFFF' }),
	},
	contactsModal: {
		icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		iconMobile: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		link: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		linkSmall: theme(themeName, { LIGHT: '#111111', DARK: '#FFF' }),
	},
	categorySettings: {
		border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
		iconButton: {
			border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
			background: theme(themeName, { LIGHT: 'transparent', DARK: '#2B2B2B' }),
			text: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFF' }),
			icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFFFFF' }),
			defaultIcon: theme(themeName, { LIGHT: '#A8A8A8', DARK: '#777f8a' }),
			modal: {
				border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
				icon: theme(themeName, { LIGHT: '#2981EE', DARK: '#FFF' }),
				title: theme(themeName, { LIGHT: '#383a50', DARK: '#FFF' }),
			}
		},
		colorButton: {
			border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38CD90' }),
			background: theme(themeName, { LIGHT: 'transparent', DARK: '#2B2B2B' }),
			text: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFF' }),
			defaultIcon: theme(themeName, { LIGHT: '#A8A8A8', DARK: '#777f8a' }),
			modal: {
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
				border: theme(themeName, { LIGHT: '#CDCDCE', DARK: '#CDCDCE' }),
				active: {
					background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3F3F46' }),
					border: theme(themeName, { LIGHT: '#38cd90', DARK: '#38cd90' }),
				}
			}
		}
	},
	productSettings: {
		text: theme(themeName, { LIGHT: '#60616e', DARK: '#A8A8B7' }),
		border: theme(themeName, { LIGHT: '#60616E', DARK: '#60616E' }),
		menuAdditional: {
			border: theme(themeName, { LIGHT: '#2981EE', DARK: '#2981EE' }),
			title: theme(themeName, { LIGHT: '#60616e', DARK: '#A8A8B7' }),
			sizes: {
				border: theme(themeName, { LIGHT: '#A8A8B7', DARK: '#A8A8B7' }),
				background: theme(themeName, { LIGHT: '#FFFFFF', DARK: '#3f3f46' }),
			}
		},
		menuDuration: {
			text: theme(themeName, { LIGHT: '#60616e', DARK: '#A8A8B7' }),
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
			disabled: theme(themeName, { LIGHT: '#A4ACB8', DARK: '#585865' }),
			error: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
			label: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
			title: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
		}
	},
	discountSettings: {
		text: theme(themeName, { LIGHT: '#000000', DARK: '#FFFFFF' }),
		title: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
		border: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
	},
	toppingGroupsSettings: {
		border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
		header: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFFFFF' }),
		title: theme(themeName, { LIGHT: '#060606', DARK: '#FFFFFF' }),
		text: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
		config: {
			border: theme(themeName, { LIGHT: '#adadb4', DARK: '#adadb4' }),
			borderProduct: theme(themeName, { LIGHT: '#A5A9B0', DARK: '#A5A9B0' }),
			table: {
				border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
				error: theme(themeName, { LIGHT: '#8F2F36', DARK: '#DE7B00' }),
				header: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
				headerSort: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				text: theme(themeName, { LIGHT: '#000', DARK: '#FFF' }),
				textGray: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
				textMobile: theme(themeName, { LIGHT: '#777f8a', DARK: '#FFFFFF' }),
				label: theme(themeName, { LIGHT: 'red', DARK: 'red' }),
			},
			filter: {
				background: theme(themeName, { LIGHT: '#e1e1e1', DARK: '#2b2b2b' }),
				title: theme(themeName, { LIGHT: '#1C1C1C', DARK: '#FFF' }),
			},
			checkbox: {
				label: theme(themeName, { LIGHT: '#383a50', DARK: '#FFF' }),
				labelExtra: theme(themeName, { LIGHT: '#60616e', DARK: '#BACCE5' }),
			}
		},
		listButton: {
			border: theme(themeName, { LIGHT: '#60616e', DARK: '#60616e' }),
			button: {
				text: theme(themeName, { LIGHT: '#2981EE', DARK: '#64A9FF' }),
				active: {
					background: theme(themeName, { LIGHT: '#2981EE', DARK: '#2263B2' }),
					text: theme(themeName, { LIGHT: '#FFF', DARK: '#FFF' }),
				}
			}
		},
	},
}
