import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ModalWrapper = styled.div`
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	font-size: 1rem;
	opacity: 1;
	top: 0;
	background-color: transparent;
	backdrop-filter: none;

	&.modal-enter {
		transform: translateY(100%);
	}

	&.modal-enter-active {
		transform: translateY(0);
		transition:  all 100ms;
	}

	&.modal-exit {
		transform: translateY(0);
	}

	&.modal-exit-active {
		opacity: 1;
		transform: translateY(100%);
		transition:  all 100ms;
	}
`
ModalWrapper.displayName = 'ModalWrapper'

export const ModalMain = styled.main`
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transform: none;
	padding: 0;
	border-radius: 0;
	overflow-y: auto;
	box-shadow: 0 4px 150px ${colorTheme.modal.header.shadow};
	min-width: auto;
	height: 100%;
	background-color: ${colorTheme.modal.background};

`
ModalMain.displayName = 'ModalMain'

export const ModalMainBlock = styled.div`
	height: 100%;
`
ModalMainBlock.displayName = 'ModalMainBlock'

export const ModalHeader = styled.h2`
	color: ${colorTheme.modal.header.defaultText};
	display: flex;
	align-items: center;
	gap: 0.45em;

	&.titleUpperCase {
		text-transform: uppercase;
	}

	position: sticky;
	z-index: 101;
	top: 0;
	padding: .5em 1.25em .5em 1.25em;
	min-height: 3.75em;
	margin: 0;
	background-color: ${colorTheme.modal.header.background};
	box-shadow: 0 4px 4px 0 ${colorTheme.modal.header.shadow};
	border-bottom: 1px solid ${colorTheme.modal.header.border};
	justify-content: space-between;
	flex-wrap: nowrap;
	text-align: start;

`
ModalHeader.displayName = 'ModalHeader'

export const ModalHeaderTitle = styled.div`
	font-size: 1em;
	display: flex;
	align-items: center;
	gap: .63em;

	:not(.big, .large, .larger, .x-larger) {
		flex-direction: column;
	}

	&.titleInOneRow {
		flex-direction: row;
	}

	@media screen and (max-width: ${theme.point820}) {
		:not(.big, .large, .larger, .x-larger) {
			flex-direction: row;
		}
	}
`
ModalHeaderTitle.displayName = 'ModalHeaderTitle'

export const ModalHeaderTitleBlock = styled.div`
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: .94em;

  > svg.header-icon {
    width: 1.63em;
    height: 2.06em;
    margin-top: -0.75em;
    margin-bottom: -0.25em;
    stroke: ${colorTheme.modal.info.icon};
  }

`
ModalHeaderTitleBlock.displayName = 'ModalHeaderTitleBlock'

export const ModalHeaderTitleText = styled.div`
	font-family: ${theme.fontRobotoBold};
	font-size: 1.5em;
`
ModalHeaderTitleText.displayName = 'ModalHeaderTitleText'

export const ModalBody = styled.div`
  padding-left: 1.25em;
  padding-right: 1.25em;
  margin-top: 1.88em;
  overflow-y: visible;
  height: auto;
  position: relative;
  padding-bottom: 6.25em;
  background-color: ${colorTheme.modal.background};

  &.height-scroll {
    max-height: none;
    overflow-y: visible;
  }

	&.fullSizeBody {
		padding: 0;
		margin: 0;
	}

	&.lightGray {
		background-color: ${colorTheme.modal.backgroundLightGray};
		min-height: 100%;
	}

`

export const ModalFooter = styled.div`
	display: flex;
	gap: 0.63em;
	justify-content: space-between;
	background-color: ${colorTheme.modal.footer.background};

	> :not(.back) {
		margin-left: auto;
	}

	&.dividedFooterLine {
		border-top: 1px solid ${colorTheme.modal.border};
	}

	&.big-gap {
		gap: 1.88em;
	}

	&.big-top {
		padding-top: 1.88em;
	}

	width: 100%;
	padding: 1.25em;
	position: fixed;
	bottom: 0;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);

	&.footerColor {
		background: ${props => props.colorFooter};
	}

	&.dividedFooterLine {
		border-color: transparent;
	}

	&.hidden {
		display: none;
	}

	&.center {
		justify-content: center;
	}

	@media screen and (max-width: ${theme.point820}) {
		> * {
			width: 100%;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		background-color: ${colorTheme.modal.background};

		&.big-gap {
			gap: 1.25em;
		}
	}
`

export const ModalCloseBlock = styled.div`
	position: absolute;
	right: 3.1em;
	top: 2.8em;
	align-items: center;

	svg {
		fill: ${colorTheme.modal.closeKey.icon};
		cursor: pointer;
		width: 2.1em;
		height: 2.1em;
	}

	display: none;

	&.mobile {
		display: flex;
		position: static;

		svg {
			fill: ${colorTheme.modal.closeKey.icon};
			width: 1.19em;
			height: 1.19em;
		}
	}

`
ModalCloseBlock.displayName = 'ModalCloseBlock'

export const ModalCloseKey = styled.div`
	width: 2.9em;
	height: 2.9em;
	box-sizing: border-box;
	border-radius: 0.5em;
	border: 1px solid ${colorTheme.modal.closeKey.border};
	background-color: ${colorTheme.modal.closeKey.background};
	color: ${colorTheme.modal.closeKey.text};
	font-family: ${theme.fontRobotoBold};
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8em;
	line-height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1.7em;
`
