import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setRestaurantCouponActivateModal, setRestaurantModalChangeCalculatorIsOpen, setRestaurantModalInvoiceConfigsIsOpen, setRestaurantModalInvoiceCustomerDataIsOpen, setRestaurantModalTerminalButtonsIsOpen, setRestaurantModalTerminalPaymentProcessIsOpen, setRestaurantPaymentTypeModal } from '../../../../../redux/action/restaurant'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import RestaurantDiscount from './RestaurantDiscount'
import { getCartPaymentInvoiceConfigsThunk, getCartPaymentTerminalsThunk, getCartPaymentThunk } from '../../../../../redux/thunk/cartPayment'
import { setCartPaymentSelectedTerminal } from '../../../../../redux/action/cartPayment'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../../../../Elements/cart/index.styled'
import Currency from '../../../../Elements/currency'
import { calculateGiftCouponsBalance } from '../../../../CashDesk/helper'
import { setGiftCoupon } from '../../../../../redux/action/coupon'
import { getGiftCouponsSum } from '../../../../../helper/getGiftCouponsPayment'
import { selectExtraDiscountItemOnTable, selectGiftCouponOnTable, selectSelectedPresetDiscount } from '../../../../../redux/selector/restaurant'
import { roundPrice } from '../../../../../helper'
import PaymentModals from './modals'
import { selectFormattedCurrency, selectHasAnyGroup, selectIsTestCompany } from '../../../../../redux/selector/system'
import { QrCodeButtonText } from '../../../../Elements/cart/ModalPayment/buttonTexts'
import { isDisabledTerminalTips } from '../../../../../redux/selector/cartPayment/helper'

const EVENT_Z_INDEX = 34
const PER_LOAD = 10
const EC_TERMINAL_LIMIT_SUM = 0.5

const ModalPaymentType = ({ title, totalPrice, totalDiscount, extraDiscountOrderItem = null, handlePaymentType, updatePaymentMode = false, resetTable }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const showPaymentTypeModal = useSelector(state => state.restaurant.showPaymentTypeModal)
	const modalChangeCalculatorIsNeeded = useSelector(store => store.restaurant.modalChangeCalculatorIsNeeded)
	const payments = useSelector(store => store.cartPayment.payments)
	const terminals = useSelector(store => store.cartPayment.terminals)
	const terminalsLength = useSelector(store => store.cartPayment.terminalsLength)
	const giftCoupon = useSelector(state => state.coupon?.giftCoupon)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasModalLogin = !!waiterAccessToken
	const hasAdminRole = useSelector(selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN']))
	const isExistInvoicePaymentConfig = !!useSelector(state => state.cartPayment.invoiceConfigs)
	const formattedEcTerminalLimitSum = useSelector(selectFormattedCurrency(EC_TERMINAL_LIMIT_SUM))

	const [ecTerminalTips, setEcTerminalTips] = useState(0)
	const [customerDiscount, setCustomerDiscount] = useState(0)
	const [customerDiscountType, setCustomerDiscountType] = useState('CURRENCY')
	const [customerDiscountHasError, setCustomerDiscountHasError] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState('')
	const [giftCoupons, setGiftCoupons] = useState([])
	const [presetDiscountId, setPresetDiscountId] = useState(null)
	const isExistPresetDiscountOrderItem = !!useSelector(selectSelectedPresetDiscount(extraDiscountOrderItem?.itemId))
	const extraDiscountItem = useSelector(selectExtraDiscountItemOnTable(presetDiscountId, totalPrice, customerDiscount, customerDiscountType))
	const extraDiscount = extraDiscountItem.value

	const hasNotGiftCouponInTable = !useSelector(selectGiftCouponOnTable)
	const giftCouponSum = getGiftCouponsSum(giftCoupons)
	const resultTotalPrice = totalPrice >= 0 ? roundPrice(totalPrice - extraDiscount) : null
	const totalPriceWithGiftCoupon = totalPrice >= 0 ? roundPrice(resultTotalPrice - giftCouponSum) : null
	const disabledRestaurantDiscount = giftCoupons.length && extraDiscount > 0
	const isTestCompany = useSelector(selectIsTestCompany)
	const disableInvoicePayment = !hasAdminRole && !isExistInvoicePaymentConfig
	const isTerminalPaymentLow = totalPriceWithGiftCoupon < EC_TERMINAL_LIMIT_SUM
	const disableTerminalPayment = !terminalsLength || isTerminalPaymentLow

	const handleResetAfterOpen = useCallback(() => {
		setCustomerDiscountType('CURRENCY')
		setCustomerDiscount(0)
		setPresetDiscountId(null)
		setGiftCoupons([])
	}, [])

	useEffect(() => {
		if (showPaymentTypeModal) {
			dispatch(getCartPaymentThunk('RESTAURANT', hasModalLogin))
			dispatch(getCartPaymentTerminalsThunk(0, PER_LOAD, hasModalLogin))
			dispatch(getCartPaymentInvoiceConfigsThunk())
		}
	}, [showPaymentTypeModal]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal) {
			handleResetAfterOpen()
		}
	}, [showPaymentTypeModal]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPaymentTypeModal && extraDiscountOrderItem) {
			if (isExistPresetDiscountOrderItem) {
				setPresetDiscountId(extraDiscountOrderItem.itemId)
			} else {
				const extraDiscountPriceWithoutMinus = Math.abs(extraDiscountOrderItem.price)
				setCustomerDiscount(extraDiscountPriceWithoutMinus)
				setCustomerDiscountType('CURRENCY')
			}
		}
	}, [showPaymentTypeModal, extraDiscountOrderItem]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const isExistGiftCoupon = giftCoupons.findIndex(item => item?.id === giftCoupon?.id)
		if (giftCoupon && isExistGiftCoupon === -1) {
			let allGiftCoupons = [...giftCoupons, { ...giftCoupon, cartBalance: 0 }]
			const giftCouponsWithBalance = calculateGiftCouponsBalance(allGiftCoupons, totalPrice - extraDiscount)
			setGiftCoupons(giftCouponsWithBalance)
			dispatch(setGiftCoupon(null))
		}
	}, [giftCoupon]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (totalPriceWithGiftCoupon <= 0 && giftCoupons.length) {
			handlePaymentType({ paymentMethod: 'GIFT_COUPON', totalDiscount, extraDiscountItem, totalPrice: resultTotalPrice, giftCoupons })
			handleClose()
		}
	}, [giftCoupons]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setRestaurantPaymentTypeModal(false))
	}, [dispatch])

	const openActivationModal = useCallback(() => {
		dispatch(setRestaurantCouponActivateModal(true))
	}, [dispatch])

	const handleInvoicePaymentClick = useCallback(() => {
		if (!isExistInvoicePaymentConfig) {
			dispatch(setRestaurantModalInvoiceConfigsIsOpen(true))
		} else {
			dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(true))
		}
	}, [isExistInvoicePaymentConfig, dispatch])

	const handlePaymentClick = useCallback((paymentMethod) => {
		setPaymentMethod(paymentMethod)
		if (paymentMethod === 'EC_TERMINAL') {
			if (terminalsLength > 1) {
				dispatch(setRestaurantModalTerminalButtonsIsOpen(true))
			} else {
				const disabledTips = isDisabledTerminalTips(terminals[0], 'RESTAURANT')
				dispatch(setCartPaymentSelectedTerminal(terminals[0]))
				if (disabledTips) {
					dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(true))
				} else {
					dispatch(setRestaurantModalChangeCalculatorIsOpen(true))
				}
			}
		} else if (paymentMethod === 'CASH' && modalChangeCalculatorIsNeeded) {
			dispatch(setRestaurantModalChangeCalculatorIsOpen(true))
		} else if (paymentMethod === 'INVOICE') {
			handleInvoicePaymentClick()
		} else {
			handlePaymentType({ paymentMethod, totalDiscount, extraDiscountItem, totalPrice: resultTotalPrice, giftCoupons })
		}
		handleClose()
	}, [terminalsLength, dispatch, handleClose, giftCoupons, handlePaymentType, modalChangeCalculatorIsNeeded, resultTotalPrice, terminals, totalDiscount, extraDiscountItem, handleInvoicePaymentClick])

	const handleModalChangePayment = useCallback(({ differenceSum }) => {
		dispatch(setRestaurantModalChangeCalculatorIsOpen(false))
		if (paymentMethod === 'EC_TERMINAL') {
			setEcTerminalTips(differenceSum)
			dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(true))
		} else {
			handlePaymentType({ paymentMethod: 'CASH', totalDiscount, extraDiscountItem, totalPrice: resultTotalPrice, giftCoupons })
		}
	}, [paymentMethod, handlePaymentType, totalDiscount, resultTotalPrice, giftCoupons, dispatch, extraDiscountItem])

	const handleDiscountChange = useCallback((e) => {
		const value = e.target.value
		setCustomerDiscount(value.replace('.', ','))
	}, [])

	const handleDiscountTypeChange = useCallback((e) => {
		const value = e.target.value
		setCustomerDiscountType(value)
	}, [])

	const couponPayment = useCallback(() => {
		setPaymentMethod('GIFT_COUPON')
		openActivationModal()
	}, [openActivationModal])

	const disablePaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'EC_TERMINAL':
				return disableTerminalPayment
			case 'INVOICE':
				return disableInvoicePayment
			case 'QR_CODE':
				return true
			default:
				return false
		}
	}, [disableInvoicePayment, disableTerminalPayment])

	const showPaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'INVOICE':
				return !updatePaymentMode
			case 'QR_CODE':
				return isTestCompany && !updatePaymentMode
			default:
				return true
		}
	}, [updatePaymentMode, isTestCompany])

	const paymentButtonMessage = useCallback((paymentMethod) => {
		const isEcTerminal = paymentMethod === 'EC_TERMINAL'
		const isInvoice = paymentMethod === 'INVOICE'
		if (isInvoice && disableInvoicePayment) {
			return { text: t('Modal.payment.invoice_disable_message') }
		} else if (isEcTerminal && isTerminalPaymentLow) {
			return { text: t('Modal.payment.terminal_disable_message', { sum: formattedEcTerminalLimitSum }) }
		} else {
			return null
		}
	}, [disableInvoicePayment, isTerminalPaymentLow, t, formattedEcTerminalLimitSum])

	const buttons = useMemo(() => {
		const paymentButtons = payments.map((payment) => {
				const isQrCode = payment.paymentMethod === 'QR_CODE'
				return ({
					icon: payment?.paymentMethod,
					text: isQrCode ? <QrCodeButtonText text={t(`Payments.payments.${payment.paymentMethod}`)} /> : t(`Payments.payments.${payment.paymentMethod}`),
					onClick: !customerDiscountHasError ? () => handlePaymentClick(payment.paymentMethod) : () => {
					},
					show: showPaymentButton(payment.paymentMethod),
					disabled: payment?.disabledForPay || disablePaymentButton(payment.paymentMethod),
					message: paymentButtonMessage(payment.paymentMethod),
				})
			},
		)
		return hasNotGiftCouponInTable ? [{
			icon: 'GIFT_COUPON', text: t('restaurant.tables.modal.coupon'), onClick: !customerDiscountHasError ? () => couponPayment() : () => {
			}, show: true, disabled: false,
		},
			...paymentButtons] : paymentButtons
	}, [payments, handlePaymentClick, customerDiscountHasError, couponPayment, hasNotGiftCouponInTable, t, disablePaymentButton, showPaymentButton, paymentButtonMessage])

	const handleTerminalPayment = useCallback((paymentId, tipsFromTerminal) => {
		const totalPriceWithTips = roundPrice(resultTotalPrice + ecTerminalTips + tipsFromTerminal)
		handlePaymentType({ paymentId, paymentMethod: 'EC_TERMINAL', tips: ecTerminalTips + tipsFromTerminal, totalDiscount, extraDiscountItem, totalPrice: totalPriceWithTips, giftCoupons })
	}, [handlePaymentType, ecTerminalTips, resultTotalPrice, totalDiscount, giftCoupons, extraDiscountItem])

	const handleInvoicePayment = useCallback((customer) => {
		dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(false))
		handlePaymentType({ paymentMethod: 'INVOICE', totalDiscount, extraDiscountItem, totalPrice: resultTotalPrice, giftCoupons, customer })
	}, [handlePaymentType, resultTotalPrice, totalDiscount, giftCoupons, extraDiscountItem, dispatch])

	return (
		<>
			<ButtonsModal isOpen={showPaymentTypeModal}
										close={handleClose}
										title={title}
										id="restaurant-payment-modal"
										size="small"
										autoFocus={false}
										zIndex={EVENT_Z_INDEX}
										buttons={buttons}>
				{giftCoupons.length > 0 ?
					<PaymentTitleWrapper className="with-gift-coupon">
						<PaymentTitle>{t('Cart.leftToPay')}: </PaymentTitle>
						<PaymentCost><Currency>{totalPriceWithGiftCoupon}</Currency></PaymentCost>
						<PaymentTitle>({t('Cart.total')}: <Currency>{resultTotalPrice}</Currency>)</PaymentTitle>
					</PaymentTitleWrapper> :
					<PaymentTitleWrapper>
						<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
						<PaymentCost><Currency>{totalPriceWithGiftCoupon}</Currency></PaymentCost>
					</PaymentTitleWrapper>}
				{hasNotGiftCouponInTable &&
					<RestaurantDiscount {...{
						titlePrice: totalPrice, customerDiscount, setCustomerDiscount, customerDiscountType, handleDiscountChange, handleDiscountTypeChange, setCustomerDiscountHasError,
						presetDiscountId, setPresetDiscountId, extraDiscountOrderItem, showPaymentTypeModal, disabled: disabledRestaurantDiscount,
					}} />}
			</ButtonsModal>

			<PaymentModals {...{ totalPriceWithGiftCoupon, ecTerminalTips, handleTerminalPayment, handleModalChangePayment, handleInvoicePayment, paymentMethod, resetTable, updatePaymentMode }} />
		</>
	)
}

ModalPaymentType.displayName = 'ModalPaymentType'
export default ModalPaymentType
